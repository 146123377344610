import axios from 'axios'
import i18n from '@/i18n'
import Bugsnag from "@bugsnag/js";

export default {
	state: {
		showCoupon: false,
		couponCodeInput: null,
		activeCoupon: null,
		checkingCoupon: false,
		applyingCoupon: false,
		couponValidError: false,
		couponValidSuccess: false,
		tempPaymentMethodInstances: null
	},
	mutations: {
		setShowCoupon(state, status) {
			state.showCoupon = status
		},
		updateCouponCodeInput(state, couponCode) {
			state.couponCodeInput = couponCode
		},
		setActiveCoupon(state, coupon) {
			state.activeCoupon = coupon
		},
		setApplyingCoupon(state, status) {
			state.applyingCoupon = status
		},
		updateCheckingCoupon(state, status) {
			state.checkingCoupon = status
		},
		setCouponValidError(state, status) {
			state.couponValidError = status
		},
		setCouponValidSuccess(state, status) {
			state.couponValidSuccess = status
		}
	},
	actions: {
		clearCoupon: ({state, commit}) => {
			commit('setApplyingCoupon', true)
			commit('setActiveCoupon', null)
			commit('updateCouponCodeInput', null)
			commit('setCouponValidError', false)
			commit('setCouponValidSuccess', false)
			commit('setApplyingCoupon', false)
			if (state.tempPaymentMethodInstances && state.tempPaymentMethodInstances.length) {
				commit('updatePaymentMethodInstances', state.tempPaymentMethodInstances)
			}
		},
		checkIfCouponIsValid: ({state, commit, getters}, {couponCode, locationUUID}) => {

			commit('updateCheckingCoupon', true)
			commit('setCouponValidError', false)
			commit('setCouponValidSuccess', false)

			const order = {
				location: locationUUID
			}

			couponCode = couponCode?.toUpperCase() ?? couponCode

			let body = {
				query: 'query Coupon($code: String!, $organization: String!, $order: NewOrder) {\n' +
					' coupon(code: $code, organization: $organization, order: $order){\n' +
					'   uuid\n' +
					'   code\n' +
					'   label\n' +
					'   description\n' +
					'   percentOff\n' +
					'	active\n' +
					'   timesRedeemed\n' +
					'   maxRedemptions\n' +
					'   item {\n' +
					'     uuid\n' +
					'     title\n' +
					'     redeemableItems\n' +
					'   }\n' +
					'   discounts{\n' +
					'     discount_id\n' +
					'     name\n' +
					'     status\n' +
					'     compatibility_strategy\n' +
					'     offer{\n' +
					'       type\n' +
					'       commerce_percentage\n' +
					'       percentage\n' +
					'       fixedAmount{\n' +
					'         amount\n' +
					'         currency_code\n' +
					'       }\n' +
					'     }\n' +
					'     conditions{\n' +
					'       name\n' +
					'       negate\n' +
					'       condition_logic_operator\n' +
					'       data\n' +
					'     }\n' +
					'   }\n' +
					' }\n' +
					'}',
				variables: {
					code: couponCode,
					organization: getters.organizationUUID,
					order: order
				}
			}

			return axios.post(getters.graphUrl, body).then(result => {
				const couponResult = result.data.data.coupon

				if (couponResult !== null && couponResult !== undefined) {

					if (couponResult.active !== true) {
						return null
					}

					const redemptionsLeft = couponResult.maxRedemptions - couponResult.timesRedeemed

					if (couponResult.maxRedemptions > 0 && redemptionsLeft <= 0) {
						commit('setActiveCoupon', null)
						commit('setCouponValidError', true)
						return null
					}

					const order = getters.order

					if (couponResult.item !== null && order !== undefined && order !== null && order.lineItems.length > 0) {
						let totalQuantity = 0
						for (const lineItem in order.lineItems) {
							const currentLineItem = order.lineItems[lineItem]

							totalQuantity += currentLineItem.quantity

							if (couponResult.maxRedemptions > 0 && totalQuantity > redemptionsLeft && !getters.zipLine2f1(couponResult)) {
								commit('setActiveCoupon', null)
								commit('setCouponValidError', true)
								return null
							}
						}
					}

					commit('setActiveCoupon', couponResult)
					commit('setCouponValidSuccess', true)

					const orderTotal = getters.orderTotal
					if (orderTotal && orderTotal.amount === 0) {
						state.tempPaymentMethodInstances = getters.paymentMethodInstances
						commit('updatePaymentMethodInstances', [getters.zeroAmountCheckoutSection])
					}
				} else {
					commit('setActiveCoupon', null)
					commit('setCouponValidError', true)
				}
				return couponResult
			}).catch((error) => {
				commit('setActiveCoupon', null)
				commit('setCouponValidError', true)
				Bugsnag.notify(error, (event) => {
					event.context = 'Axios Error On Checking If Coupon Is Valid [graph][coupon.js]'
				})
			}).finally(() => {
				commit('updateCheckingCoupon', false)
			})
		}
	},
	getters: {
		showCouponInput: state => {
			return state.showCoupon
		},
		activeCoupon: state => {
			return state.activeCoupon
		},
		hasActiveCoupon: state => {
			return !!state.activeCoupon
		},
        zipLine2f1: state => (payload) => {
            if(payload !== null && payload !== undefined && payload.label !== null && payload.label !== undefined) {
                return payload.label.includes('Fluglínan 2F1')
            }
            if(state.activeCoupon !== null && state.activeCoupon !== undefined && state.activeCoupon.label !== null && state.activeCoupon.label !== undefined) {
                return state.activeCoupon.label.includes('Fluglínan 2F1')
            }
            return false
        },
		couponDiscountPercentage: (state, getters) => {
			if (getters.hasActiveCoupon) {
				try {
					let discount = parseInt(state.activeCoupon.percentOff)

					// The below if statement restricts any applied coupon from being over 100%
					if (discount > 100) {
						discount = 100
					}
					return discount
				} catch (e) {
					return null
				}
			} else {
				return null
			}
		},
		couponCode: (state, getters) => {
			if (getters.hasActiveCoupon) {
				return state.activeCoupon.code
			} else {
				return null
			}
		},
		processingCoupon: state => {
			return state.checkingCoupon || state.applyingCoupon
		},
		couponCodeInput: state => {
			return state.couponCodeInput
		},
		couponValidError: state => {
			return state.couponValidError
		},
		couponValidSuccess: state => {
			return state.couponValidSuccess
		},
		activeCouponText: (state, getters) => {
			const coupon = state.activeCoupon
			if (coupon) {
				let text = coupon.code + ' - ' + coupon.label
				if (!getters.zipLine2f1) {
					return text + ' - ' + coupon.percentOff + '% ' + i18n.t('coupon.discount')
				}
				return text
			} else {
				return ''
			}
		},
		isLineItemRedeemable: (state, getters) => (lineItem) => {

			// if the coupon.item has redeemableItems property then check if the
			if (!getters.hasActiveCoupon) {
				return false
			}

			const coupon = state.activeCoupon
			// If the coupon has no redeemable items' limitation then return true
			if (!coupon.item || !Array.isArray(coupon.item.redeemableItems) || coupon.item.redeemableItems.length <= 0) {
				return true
			}

			// The coupon has redeemable items' limitations due to the check above. Now check if the line item is part of the redeemable items
			return coupon.item.redeemableItems.some(redeemableItem => redeemableItem === lineItem.item.uuid)

		},
		couponLineItemDiscount: (state, getters) => (lineItem) => {
			const excludeDiscounts = lineItem.item.exclude_discounts
			const itemIsRedeemable = getters.isLineItemRedeemable(lineItem)

			if (!excludeDiscounts && lineItem.unitPrice && lineItem.unitPrice.amount && itemIsRedeemable) {
				const itemTotal = lineItem.unitPrice.amount * lineItem.quantity

				// POS and "I assume" Legacy are rounding the total line item price after subtracting the discount, not the discount amount that is to be subtracted.
				// Due to this inconsistancy, orders with certain discount percentages and line item amounts are causing the order
				// to end up with balances of 1 kr. for each of those items

				// POS DISCOUNT APPLICATION CODE
				// float percent = (float) (getDiscountPercent()/100.0);
				// float totalAmount = (commerceUnitPrice.getAmount() - (commerceUnitPrice.getAmount() * percent)) * Float.valueOf(getQuantity());
				// commerceTotal.setAmount(Math.round(totalAmount));
				
				return (getters.couponDiscountPercentage / 100) * itemTotal
			} 
			
			else {
				return 0
			}
		},
		couponTotalDiscount: (state, getters) => {
			const lineItems = getters.order.lineItems
			return {
				amount: lineItems.reduce((sum, lineItem) => {
					return sum + (lineItem.unitPrice.amount - Math.round(lineItem.unitPrice.amount - getters.couponLineItemDiscount(lineItem)))
				}, 0),
				currency_code: lineItems[0].unitPrice.currency_code
			}
		}
	}
}
