import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import store from './store'
import router from './router'
import i18n from './i18n'
import * as Resize from './iframeResizer.contentWindow.min.js'
import VCalendar from 'v-calendar'
import VueGtag from "vue-gtag"
import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'
import {version} from '../package.json'

const currencyFormatter = require('currency-formatter')

console.info("SalesCloud CheckOut", version)

Bugsnag.start({
    apiKey: process.env.VUE_APP_BUGSNAG_API_KEY,
    plugins: [new BugsnagPluginVue()],
    appVersion: version
})

Vue.config.productionTip = false
Vue.use(Resize)

const bugsnagVue = Bugsnag.getPlugin('vue')
bugsnagVue.installVueErrorHandler(Vue)

// Use v-calendar & v-date-picker components
Vue.use(VCalendar, {
  componentPrefix: 'vc',  // Use <vc-calendar /> instead of <v-calendar />
})

Vue.filter('formattedPrice', function (value) {
    if (!value) return '0 ISK'

    let amount = value.amount
    const currency = value.currency_code

    const currencyInfo = currencyFormatter.findCurrency(currency)
    if (currencyInfo && currencyInfo.decimalDigits > 0) {
        amount = amount / Math.pow(10, currencyInfo.decimalDigits)
    }

    return currencyFormatter.format(amount, {code: currency})
})

Vue.use(VueGtag, {
  bootstrap: false
})

new Vue({
  vuetify,
  store,
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')
