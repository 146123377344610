import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import i18n from '../i18n'
import {v4 as uuidv4} from 'uuid'
import {setOptions} from "vue-gtag"
import coupon from "@/store/coupon"
import analytics from "@/store/analytics"
import hardcode from "./hardcode"
import Vuetify from '../plugins/vuetify'
import Bugsnag from "@bugsnag/js";


/**
 * @property {Object} cart
 * @property {Array} checkoutStepList
 * @property {Array} paymentMethodList
 * @property {Array} paymentMethodInstanceList
 * @property {Array} checkoutSectionList
 * @property {Array} checkoutSectionInstanceList
 * @property {Array} bookings
 * @property {String} primary_color
 * @property {String} accent_color
 * @property {String} uuid
 */

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		graphUrl: 'https://graph.salescloud.is',
		// graphUrl: 'http://localhost:8080',
		paymentRequestUrl: 'https://service-payment-request.salescloud.is/v/1',
		// paymentRequestUrl: 'http://localhost:8081/v/1',
		order: null,
		channelUUID: null,
		organizationUUID: null,
		locationUUID: null,
		organization: null,
		channel: null,
		sessionToken: null,
		anonymousSession: null,
		checkoutSectionInstances: null,
		checkoutSections: null,
		checkoutSteps: null,
		paymentMethods: null,
		paymentMethodInstances: null,
		selectedPaymentMethodInstanceIndex: null,
		cartUUID: null,
		ssnOptional: false,
		progressingOrder: false,
		deletingLineItem: false,
		systemTimeInMillis: null,
		reviewSections: [],
		paymentInProgress: false,
		gettingAvailableTimeSlots: false,
		reservingSlot: false,
		releasingSlot: false,
		selectedTimeSlots: [],
		showCheckoutAvatar: false,
		rulesFulfilled: false,
		paymentResponse: null,
		processingPayment: false,
		checkoutSectionInstancesData: [],
		selectedLineItem: null,
		analyticsIDs: [],
		paymentIsPending: false,
		pendingPaymentLastCheckedDate: null,
		termsAgreedTo: false,
		gettingOrderBookings: false,
		orderBookings: [],
		gettingOrderSubscriptions: false,
		orderSubscriptions: [],
		deliveryServices: [],
		selectedDeliveryService: null,
		selectedDeliveryServicePrice: null,
		redeemableItems: [],
		dependentPayments: [],
		hvammsvik: '06a69a1f-8164-422e-b7d8-3753e04f6464',
		inIframe: false,
		namespacesWhitelistedForDependentPayment: ['giftcard', 'yay']
	},
	mutations: {
		updateInIframe(state, inIframe) {
			state.inIframe = inIframe
		},
		setDependentPayments(state, dependentPayments) {
			state.dependentPayments = dependentPayments
		},
		addDependentPayment(state, payment) {
			if (!Array.isArray(state.dependentPayments)) {
				state.dependentPayments = []
			}
			state.dependentPayments.push(payment)
		},
		removeDependentPayment(state, index) {
			state.dependentPayments.splice(index, 1)
		},
		setTranslations(state, translations) {
			const keys = Object.keys(translations)
			for (let i = 0; i < keys.length; i++) {
				i18n.mergeLocaleMessage(keys[i], translations[keys[i]])
			}
		},
		updateRedeemableItems(state, items) {
			state.redeemableItems = items
		},
		updateGettingOrderBookings(state, status) {
			state.gettingOrderBookings = status
		},
		updateOrderBookings(state, bookings) {
			state.orderBookings = bookings
		},
		updateGettingOrderSubscriptions(state, status) {
			state.gettingOrderSubscriptions = status
		},
		updateOrderSubscriptions(state, subscriptions) {
			state.orderSubscriptions = subscriptions
		},
		updateBillingName(state, name) {
			for (let i in state.checkoutSectionInstances) {
				if (state.checkoutSectionInstances[i].namespace === 'billing_minimal' || state.checkoutSectionInstances[i].namespace === 'customer_profile_billing') {
					for (let b in state.checkoutSectionInstances[i].uiFields) {
						if (state.checkoutSectionInstances[i].uiFields[b].key === 'name') {
							state.checkoutSectionInstances[i].uiFields[b].value = name
						}
					}
				}
			}
		},
		updatePendingPaymentLastCheckedDate(state, timestamp) {
			state.pendingPaymentLastCheckedDate = timestamp
		},
		updatePaymentIsPending(state, status) {
			state.paymentIsPending = status
		},
		updateSelectedPaymentMethodInstanceIndex(state, index) {
			state.selectedPaymentMethodInstanceIndex = index
		},
		updateAnalyticsIDs(state, ids) {
			state.analyticsIDs = ids
		},
		updateCheckoutInstanceData(state, data) {
			state.checkoutSectionInstancesData = data
		},
		updateProcessingPayment(state, status) {
			state.processingPayment = status
		},
		setPaymentResponse(state, response) {
			state.paymentResponse = response
		},
		updateRulesFulfilled(state, status) {
			state.rulesFulfilled = status
		},
		updateShowCheckoutAvatar(state, status) {
			state.showCheckoutAvatar = status
		},
		addToSelectedTimeSlots(state, slot) {
			state.selectedTimeSlots.push(slot)
		},
		removeFromSelectedTimeSlots(state, slot) {
			let index = state.selectedTimeSlots.findIndex(s => s.key === slot.key)
			state.selectedTimeSlots = state.selectedTimeSlots.slice(index, 1)
		},
		updateReservingSlot(state, status) {
			state.reservingSlot = status
		},
		updateReleasingSlot(state, status) {
			state.releasingSlot = status
		},
		updateGettingAvailableTimeSlots(state, status) {
			state.gettingAvailableTimeSlots = status
		},
		updateProgressingOrder(state, isProgressing) {
			state.progressingOrder = isProgressing
		},
		updateDeletingLineItem(state, isDeletingLineItem) {
			state.deletingLineItem = isDeletingLineItem
		},
		updateCart: (state, cart) => {
			Bugsnag.clearMetadata('cart')
			state.order = cart
			if(cart) {
				Bugsnag.addMetadata('cart', cart)
			}
		},
		updateCartUUID: (state, cartUUID) => {
			state.cartUUID = cartUUID
		},
		updateAnonymousSession: (state, anonymousSession) => {
			state.anonymousSession = anonymousSession
			state.cartUUID = anonymousSession.cart
		},
		updateSessionToken: (state, sessionToken) => {
			state.sessionToken = sessionToken
		},
		updateOrganization: (state, organization) => {
			Bugsnag.clearMetadata('organization')
			state.organization = organization
			if(organization) {
				Bugsnag.addMetadata('organization', organization)
			}
			if (
				Vuetify.framework !== null && typeof Vuetify.framework !== 'undefined' &&
				Vuetify.framework.theme !== null && typeof Vuetify.framework.theme !== 'undefined' &&
				Vuetify.framework.theme.themes !== null && typeof Vuetify.framework.theme.themes !== 'undefined' &&
				organization.primary_color !== null && typeof organization.primary_color !== 'undefined'
			) {
				if (Vuetify.framework.theme.themes.light !== null && typeof Vuetify.framework.theme.themes.light !== 'undefined') {
					Vuetify.framework.theme.themes.light.primary = organization.primary_color
					Vuetify.framework.theme.themes.light.secondary = organization.accent_color
				}
				if (Vuetify.framework.theme.themes.dark !== null && typeof Vuetify.framework.theme.themes.dark !== 'undefined') {
					Vuetify.framework.theme.themes.dark.primary = organization.primary_color
					Vuetify.framework.theme.themes.dark.secondary = organization.accent_color
				}
			}
		},
		updatePaymentMethodInstances: (state, paymentMethodInstances) => {
			state.paymentMethodInstances = paymentMethodInstances
		},
		updatePaymentMethods: (state, paymentMethods) => {
			state.paymentMethods = paymentMethods
		},
		updateCheckoutSteps: (state, checkoutSteps) => {

			if (state.organizationUUID === "2baf8f91-c585-4092-a716-15724a98b76f") {
				checkoutSteps.map(c => {
					if (c.namespace === 'delivery')
						c.namespace = 'shipping'
				})
			}
			state.checkoutSteps = checkoutSteps
		},
		updateCheckoutSections: (state, checkoutSections) => {
			state.checkoutSections = checkoutSections
		},
		updateCheckoutSectionInstances: (state, checkoutSectionInstances) => {

			for (let i in checkoutSectionInstances) {

				if (checkoutSectionInstances[i].namespace === 'terms_and_conditions') {
					checkoutSectionInstances[i].weight = 99
				}

				if (checkoutSectionInstances[i].uiFields === null || checkoutSectionInstances[i].uiFields === undefined) {
					continue
				}

				checkoutSectionInstances[i].uiFields = checkoutSectionInstances[i].uiFields.sort((a, b) => a.weight - b.weight)

				const organizationsWithOptionalSSNInput = [
					'bb78d411-9b7c-4a51-a134-e359cc1bc7f8', // Bullseye
					'b105185f-96b9-4fe8-841c-e1d62f9a2138', // Flugakademían
					'5878c3e9-edc0-4382-b521-1e5214325ca3'  // Brons
				]
				if (state.ssnOptional === true || organizationsWithOptionalSSNInput.includes(state.organizationUUID)) { //Make SSN optional for Bullseye
					checkoutSectionInstances[i].uiFields.forEach(field => {
						if (field.key === 'ssn') {
							field.required = false
						}
					})
				}
			}

			state.checkoutSectionInstances = checkoutSectionInstances.sort((c1, c2) => c1.weight - c2.weight)
		},
		updateChannel: (state, channel) => {

			try {
				channel.data = JSON.parse(channel.data)
			} catch (e) {
				// Do nothing
			}

			state.channel = channel
		},
		updateChannelUUID: (state, uuid) => {
			state.channelUUID = uuid
		},
		updateOrganizationUUID: (state, uuid) => {
			state.organizationUUID = uuid
		},
		updateLocationUUID: (state, uuid) => {
			state.locationUUID = uuid
		},
		updateSystemTimeInMillis: (state, timestamp) => {
			state.systemTimeInMillis = timestamp
		},
		updateSelectedLineItem: (state, lineItem) => {
			state.selectedLineItem = lineItem
		},
		updateTermsAgreedTo: (state, termsState) => {
			state.termsAgreedTo = termsState
		},
		setDeliveryServices: (state, services) => {
			state.deliveryServices = services
		},
		updateSelectedDeliveryService: (state, service) => {
			state.selectedDeliveryService = service
		},
		updateSelectedDeliveryServicePrice: (state, price) => {
			state.selectedDeliveryServicePrice = price
		}
	},
	actions: {
		getOrderBookings: ({state, commit, dispatch}, counter) => {
			// if(counter === null || counter === undefined) {
			//     counter = 0
			// }

			let body = {
				query: 'query {\n' +
					'   bookingsByOrder(organization: "' + state.organizationUUID + '", order: "' + state.cartUUID + '") {\n' +
					'       uuid\n' +
					'       booking_id\n' +
					'       description\n' +
					'       quantity\n' +
					'       state\n' +
					'       startsAtTime\n' +
					'       endsAtTime\n' +
					'       externalBookingId\n' +
					'       externalDistributionUrl\n' +
					'       item {\n' +
					'           uuid\n' +
					'           title\n' +
					'           description {\n' +
					'               value\n' +
					'               summary\n' +
					'           }\n' +
					'           images {\n' +
					'               file {\n' +
					'                   sources {\n' +
					'                       medium\n' +
					'                   }\n' +
					'               }\n' +
					'           }\n' +
					'       }\n' +
					'       location {\n' +
					'           uuid\n' +
					'           label\n' +
					'       }\n' +
					'   }\n' +
					'}'
			}

			return axios.post(state.graphUrl, body).then(result => {

				// Base case for recursive result
				if (result && result.data && result.data.data) {
					let bookingsByOrderResult = result.data.data.bookingsByOrder
					if (Array.isArray(bookingsByOrderResult) && bookingsByOrderResult.length > 0) {
						// Special case for BClub/B Reykjavík
						if (state.organizationUUID !== 'c9632662-ce96-4261-91ee-eef98f5e9223'
							|| (state.organizationUUID === 'c9632662-ce96-4261-91ee-eef98f5e9223'
								&& bookingsByOrderResult.every(booking => booking.externalDistributionUrl !== null && booking.externalDistributionUrl !== undefined))) {
							commit('updateOrderBookings', bookingsByOrderResult)
							commit('updateGettingOrderBookings', false)
							return bookingsByOrderResult
						}
					}
				}

				// Secondary base case for recursive result
				// 30 * 2000 = 60000 => 1 minute in milliseconds
				if (counter >= 30) {
					commit('updateOrderBookings', [])
					commit('updateGettingOrderBookings', false)
					return []
				}

				// Recursive call
				setTimeout(() => {
					counter = counter + 1
					return dispatch('getOrderBookings', counter).then(result => result)
				}, 2000)
			}).catch((error) => {
				Bugsnag.notify(error, (event) => {
					event.context = 'Axios Error On Getting Order Bookings [graph][index.js]'
				})
			})
		},
		getOrderSubscriptions: ({state, commit, dispatch}, counter) => {
			let body = {
				query: 'query {\n' +
					'   subscriptionsByOrder(organization: "' + state.organizationUUID + '", order: "' + state.cartUUID + '") {\n' +
					'       uuid\n' +
					'       label\n' +
					'       nextPayment\n' +
					'       externalId\n' +
					'       externalDistributionUrl\n' +
					'       item {\n' +
					'           uuid\n' +
					'           title\n' +
					'           description {\n' +
					'               value\n' +
					'               summary\n' +
					'           }\n' +
					'           images {\n' +
					'               file {\n' +
					'                   sources {\n' +
					'                       medium\n' +
					'                   }\n' +
					'               }\n' +
					'           }\n' +
					'       }\n' +
					'   }\n' +
					'}'
			}

			return axios.post(state.graphUrl, body).then(result => {
				// Base case for recursive result
				if (result && result.data && result.data.data) {
					let subscriptionsByOrderResult = result.data.data.subscriptionsByOrder
					if (Array.isArray(subscriptionsByOrderResult) && subscriptionsByOrderResult.length > 0) {
						if (state.organizationUUID !== 'c9632662-ce96-4261-91ee-eef98f5e9223'
							|| (state.organizationUUID === 'c9632662-ce96-4261-91ee-eef98f5e9223'
								&& subscriptionsByOrderResult.every(subscription => subscription.externalDistributionUrl !== null && subscription.externalDistributionUrl !== undefined))) {
							commit('updateOrderSubscriptions', subscriptionsByOrderResult)
							commit('updateGettingOrderSubscriptions', false)
							return subscriptionsByOrderResult
						}
					}
				}

				// Secondary base case for recursive result
				// 30 * 2000 = 60000 => 1 minute in milliseconds
				if (counter >= 30) {
					commit('updateOrderSubscriptions', [])
					commit('updateGettingOrderSubscriptions', false)
					return []
				}

				// Recursive call
				setTimeout(() => {
					counter = counter + 1
					return dispatch('getOrderSubscriptions', counter).then(result => result)
				}, 2000)
			}).catch((error) => {
				Bugsnag.notify(error, (event) => {
					event.context = 'Axios Error On Getting Order Subscriptions [graph][index.js]'
				})
			})
		},
		// eslint-disable-next-line no-empty-pattern
		getNameFromSSN: ({}, ssn) => {
			return axios.get('https://europe-west1-salescloud.cloudfunctions.net/thjodskraLookup?kennitala=' + ssn).then(response => {
				return response.data
			}).catch((error) => {
				Bugsnag.notify(error, (event) => {
					event.context = 'Axios Error On Getting Name From Ssn [cloud-function][index.js]'
				})
				return null
			})
		},
		checkPendingPayment: ({state, commit, dispatch}, payload) => {
			payload.retries = 0

			const orderStatusUrl = state.paymentRequestUrl + '/' + state.organizationUUID + '/' + state.channelUUID + '/' + payload.paymentInstanceUUID + '/' + payload.orderUUID

			setTimeout(function () {
				axios.get(orderStatusUrl).then(response => {
					const statusResponse = response.data
					if (statusResponse && (statusResponse.paymentTransaction && statusResponse.paymentTransaction.status === 'pending' || statusResponse.paymentTransaction === null)) {
						payload.retries += 1
						commit('updatePendingPaymentLastCheckedDate', Date.now())
						dispatch('checkPendingPayment', payload).then()
					} else {
						commit('setPaymentResponse', statusResponse)
					}
				}).catch((error) => {
					Bugsnag.notify(error, (event) => {
						event.context = 'Axios Error On Checking Pending Payment [payment-request-service][index.js]'
					})
				})
			}, 5000)
		},
		updateLineItem: ({state}, payload) => {
			state.order.lineItems[payload.index].selectedDate = payload.date
		},
		processPayment: async ({state, commit}, payload) => {
			commit('updateProcessingPayment', true)

			const selectedPaymentMethodInstance = payload.selectedPaymentMethodInstance
			const paymentRequest = payload.paymentRequest

			const paymentUrl = state.paymentRequestUrl + '/' + state.organizationUUID + '/' + state.channelUUID + '/' + selectedPaymentMethodInstance.uuid

			// In the case of landsbankinn, we need to do client-side encryption for the card details to send to PSR.
			if (paymentRequest.namespace === 'verifone_ecommerce') {

				const card = {
					cardNumber: paymentRequest.details.cardNumber,
					expiryMonth: paymentRequest.details.expiryMonth,
					expiryYear: paymentRequest.details.expiryYear,
					cvv: paymentRequest.details.cardSecurityCode
				}
				
				try {
					paymentRequest.details.cardNumber = await window.verifone.encryptCard(card, process.env.VUE_APP_VERIFONE_SECURE_CAPTURE_KEY)
					delete paymentRequest.details.expiryMonth
					delete paymentRequest.details.expiryYear
					delete paymentRequest.details.cardSecurityCode
				}catch(e) {
					console.warn("[STORE][PAYMENTREQUEST][verifone_ecommerce][window.verifone.encryptCard]" , e)
				}	

			}

			return axios.post(paymentUrl, paymentRequest)
				.then(response => {
					if (response.data) {
						commit('setPaymentResponse', response.data)
					}

				})
				.catch((error) => {
					Bugsnag.notify(error, (event) => {
						event.context = 'Axios Error On Processing Payment [payment-request-service][index.js]'
					})
				})
				.finally(() => {
					commit('updateProcessingPayment', false);
				})
		},
		processCheckoutStep: ({state}, payload) => {
			const query = {
				query: 'mutation processCheckoutStep($values: [CheckoutSectionValues]) {\n' +
					'  processCheckoutStep(sessionToken: "' + state.sessionToken + '", organization: "' + state.organizationUUID + '", channel: "' + state.channelUUID + '", step: "' + payload.step + '", values: $values) {\n' +
					'    success\n' +
					'    message\n' +
					'    errorLevel\n' +
					'    errors {\n' +
					'     namespace\n' +
					'     message\n' +
					'    }\n' +
					'  }\n' +
					'}',
				variables: {
					values: payload.values
				}
			}

			return axios.post(state.graphUrl, query).catch((error) => {
				Bugsnag.notify(error, (event) => {
					event.context = 'Axios Error On Processing Checkout Step [graph][index.js]'
				})
			})
		},
		getAnonymousSession: ({state, commit, getters}) => {

			const query = {
				query: 'query {\n' +
					'  anonymousSession(sessionToken: "' + state.sessionToken + '", channel: "' + state.channelUUID + '") {\n' +
					'    session_token\n' +
					'    ttl\n' +
					'    cart\n' +
					'  }\n' +
					'  systemTimeInMillis\n' +
					'  cart(sessionToken: "' + state.sessionToken + '", channel: "' + state.channelUUID + '") {\n' +
					'    uuid\n' +
					'    entangleKey\n' +
					'    location{\n' +
					'       label\n' +
					'    }\n' +
					'    orderTotal{\n' +
					'       amount\n' +
					'       currency_code\n' +
					'    }\n' +
					'    lineItems {\n' +
					'     uuid\n' +
					'     variations {\n' +
					'        uuid\n' +
					'        label\n' +
					'        price{\n' +
					'           amount\n' +
					'           currency_code\n' +
					'        }\n' +
					'     }\n' +
					'     dynamicDescription\n' +
					'     line_item_label\n' +
					'     item {\n' +
					'       title\n' +
					'       uuid\n' +
					'       sku\n' +
					'       categories{\n' +
					'         uuid\n' +
					'         name\n' +
					'       }\n' +
					'       price{\n' +
					'         amount\n' +
					'         currency_code\n' +
					'       }\n' +
                    '       accommodations {\n' +
                    '         uuid\n' +
					'         title\n' +
                    '         sku\n' +
                    '         description {\n' +
                    '           value\n' +
                    '         }\n' +
					'         price {\n' +
                    '           amount\n' +
                    '           currency_code\n' +
                    '         }\n' +
                    '         categories {\n' +
                    '           uuid\n' +
                    '           name\n' +
                    '         }\n' +
					'       }\n' +
					'       sale_price{\n' +
					'         amount\n' +
					'         currency_code\n' +
					'       }\n' +
					'       images{\n ' +
					'         file{\n ' +
					'           sources{\n' +
					'             thumbnail\n' +
					'           }\n' +
					'         }\n' +
					'       }\n' +
					'       exclude_discounts\n' +
					'     }\n' +
					'     quantity\n' +
					'     locked\n' +
					'     processed\n' +
					'     namespace\n' +
					'     unitPrice {\n' +
					'       amount\n' +
					'       currency_code\n' +
					'     }\n' +
					'     totalPrice {\n' +
					'       amount\n' +
					'       currency_code\n' +
					'     }\n' +
					'     bookings {\n' +
					'       uuid\n' +
					'       description\n' +
					'       startsAtTime\n' +
					'       endsAtTime\n' +
					'     }\n' +
					'    }\n' +
					'    state\n' +
					'    created\n' +
					'    changed\n' +
					'  }\n' +
					' organizationTranslationsI18nFormatted(organization: "' + state.organizationUUID + '")\n' +
					'}'
			}

			return axios.post(state.graphUrl, query).then(response => {

				if (response.data.data) {
					commit('updateAnonymousSession', response.data.data.anonymousSession)

					const systemTime = response.data.data.systemTimeInMillis
					commit('updateSystemTimeInMillis', systemTime)
					commit('updateCart', response.data.data.cart)
					commit('updateSelectedLineItem', response?.data?.data?.cart?.lineItems?.[0] ?? null)
					commit('setTranslations', response.data.data.organizationTranslationsI18nFormatted)

					// Handling zero amount orders, they will only need customers to complete a checkout rather than input payment details
					// Rather than creating a specific view for this case we just replace the payment method instances with a custom one and use
					// what has already been defined in components to render
					const orderTotal = getters.orderTotal
					if (orderTotal && orderTotal.amount === 0) {
						state.paymentMethodInstances = [getters.zeroAmountCheckoutSection]
					}

					return response.data.data.cart
				}
				return null
			}).catch((error) => {
				Bugsnag.notify(error, (event) => {
					event.context = 'Axios Error On Getting Anonymous Session [graph][index.js]'
				})
			})

		},
		checkOrderTotal: ({commit, getters}) => {
			const orderTotal = getters.orderTotal
			if (orderTotal !== null && typeof orderTotal !== 'undefined' && orderTotal.amount === 0) {
				commit('updatePaymentMethodInstances', [getters.zeroAmountCheckoutSection])
			}
		},
		deleteLineItem: ({state, commit}) => (lineItem) => {

			const query = {
				query: 'query {\n' +
					'  channel(organization: "' + lineItem.uuid + '") {\n' +
					'}'
			}

			commit('updateDeletingLineItem', true)

			axios.post(state.graphUrl, query).then(response => {
				if (response.data.data) {
					commit('updateChannel', response.data.data.channel)
				}
			}).catch((error) => {
				Bugsnag.notify(error, (event) => {
					event.context = 'Axios Error On Deleting Line Item [graph][index.js]'
				})
			}).finally(() => {
				commit('updateDeletingLineItem', false)
			})

		},
		progressOrder: ({state, commit}) => {

			const query = {
				query: 'query {\n' +
					'}'
			}

			commit('updateProgressingOrder', true)

			axios.post(state.graphUrl, query).then(response => {

				if (response.data.data) {
					commit('updateChannel', response.data.data.channel)
				}

			}).catch((error) => {
				Bugsnag.notify(error, (event) => {
					event.context = 'Axios Error On Progressing Order [graph][index.js]'
				})
			}).finally(() => {
				commit('updateProgressingOrder', false)
			})

		},
		getDeliveryServiceRateLegacyOrder: ({state}, deliveryService) => {
			if (typeof state.order !== 'undefined' && state.order !== null && typeof state.order.uuid !== 'undefined' && state.order.uuid !== null) {
				if (typeof deliveryService !== 'undefined' && deliveryService !== null) {
					const deliveryServiceUUID = deliveryService.uuid
					const body = {
						query: 'query {\n' +
							'   deliveryServiceRateLegacyOrder(organization: "' + state.organizationUUID + '", deliveryMethodInstance: "' + deliveryServiceUUID + '", order: "' + state.cartUUID + '") {\n' +
							'       amount\n' +
							'       currency_code\n' +
							'   }\n' +
							'}'
					}

					return axios.post(state.graphUrl, body).then(result => {
						if (result && result.data && result.data.data && result.data.data) {
							const {deliveryServiceRateLegacyOrder} = result.data.data
							if (deliveryServiceRateLegacyOrder && typeof deliveryServiceRateLegacyOrder.amount !== 'undefined') {
								return deliveryServiceRateLegacyOrder
							}
						}
						return null
					}).catch((error) => {
						Bugsnag.notify(error, (event) => {
							event.context = 'Axios Error On Getting Delivery Service Rate Legacy Order [graph][index.js]'
						})
					})
				}
			}
			return null
		},
		getInitialData: ({state, commit, getters}) => {

			let additionalQuery = ''
			if (getters.isSmarabio) {
				additionalQuery += `    itemsList(organization: "${state.organizationUUID}") {\n
                    uuid\n
                    title\n
                    sku\n
                    categories {\n
                        uuid\n
                        name\n
                    }\n
                }\n`
			}

			let query = {
				query: 'query {\n' +
					'  channel(organization: "' + state.organizationUUID + '", uuid: "' + state.channelUUID + '") {\n' +
					'    label\n' +
					'    description\n' +
					'    ept\n' +
					'    created\n' +
					'    changed\n' +
					'    acceptedPaymentMethodInstances {\n' +
					'       uuid\n' +
					'       namespace\n' +
					'    }\n' +
					'    data\n' +
					'  }\n' +
					'  organization(uuid: "' + state.organizationUUID + '") {\n' +
					'    uuid\n' +
					'    label\n' +
					'    description\n' +
					'    email\n' +
					'    phone\n' +
					'    accent_color\n' +
					'    primary_color\n' +
					'    logo {\n' +
					'      title\n' +
					'      alt\n' +
					'      file {\n' +
					'        sources {\n' +
					'          original\n' +
					'          thumbnail\n' +
					'          large\n' +
					'        }\n' +
					'      }\n' +
					'    }\n' +
					'    settings {\n' +
					'      booleans {\n' +
					'        key\n' +
					'        value\n' +
					'      }\n' +
					'      strings {\n' +
					'        key\n' +
					'        value\n' +
					'      }\n' +
					'      stringLists {\n' +
					'        key\n' +
					'        value\n' +
					'      }\n' +
					'      integers {\n' +
					'        key\n' +
					'        value\n' +
					'      }\n' +
					'    }\n' +
					'  }\n' +
					'  paymentMethodInstanceList(organization: "' + state.organizationUUID + '", checkout: true) {\n' +
					'    namespace\n' +
					'    icon\n' +
					'    title\n' +
					'    description\n' +
					'    uuid\n' +
					'    checkout\n' +
					'    alertMessage\n' +
					'    uiFields {\n' +
					'      key\n' +
					'      label\n' +
					'      type\n' +
					'      cols\n' +
					'      value\n' +
					'      required\n' +
					'      options {\n' +
					'       key\n' +
					'       label\n' +
					'      }\n' +
					'     }\n' +
					'  }\n' +
					'  checkoutSectionInstanceList(organization: "' + state.organizationUUID + '", channel: "' + state.channelUUID + '", order: "' + state.cartUUID + '") {\n' +
					'    title\n' +
					'    description\n' +
					'    namespace\n' +
					'    step\n' +
					'    data\n' +
					'    weight\n' +
					'    uiFields {\n' +
					'      key\n' +
					'      label\n' +
					'      type\n' +
					'      value\n' +
					'      required\n' +
					'      minLength\n' +
					'      weight\n' +
					'      options {\n' +
					'       key\n' +
					'       label\n' +
					'      }\n' +
					'    }\n' +
					'  }\n' +
					'  checkoutSectionList(organization: "' + state.organizationUUID + '") {\n' +
					'    title\n' +
					'    description\n' +
					'    namespace\n' +
					'    uiFields {\n' +
					'      key\n' +
					'      label\n' +
					'      value\n' +
					'      type\n' +
					'    }\n' +
					'  }\n' +
					'  checkoutStepList(organization: "' + state.organizationUUID + '") {\n' +
					'    title\n' +
					'    description\n' +
					'    namespace\n' +
					'  }\n' +
					additionalQuery +
					'  deliveryServices(organization: "' + state.organizationUUID + '", channel: "' + state.channelUUID + '", order: "' + state.cartUUID + '") {\n' +
					'    uuid\n' +
					'    title\n' +
					'    description\n' +
					'    namespace\n' +
					'    enabled\n' +
					'    available {\n' +
					'       isAvailable\n' +
					'    }\n' +
					'    price {\n' +
					'       amount\n' +
					'       currency_code\n' +
					'    }\n' +
					'    uiFields {\n' +
					'      key\n' +
					'      label\n' +
					'      type\n' +
					'      value\n' +
					'      required\n' +
					'      options {\n' +
					'       key\n' +
					'       label\n' +
					'      }\n' +
					'    }\n' +
					'  }\n' +
					'  systemTimeInMillis\n' +
					'}\n'
			}

			if (state.channelUUID === 'b28077c1-ce54-4cdc-87e8-e636e5457415') {
				query = {
					query: 'query {\n' +
						'  channel(organization: "' + state.organizationUUID + '", uuid: "' + state.channelUUID + '") {\n' +
						'    label\n' +
						'    description\n' +
						'    ept\n' +
						'    created\n' +
						'    changed\n' +
						'    data\n' +
						'    acceptedPaymentMethodInstances {\n' +
						'       uuid\n' +
						'       namespace\n' +
						'    }\n' +
						'  }\n' +
						'  organization(uuid: "' + state.organizationUUID + '") {\n' +
						'    uuid\n' +
						'    label\n' +
						'    description\n' +
						'    email\n' +
						'    phone\n' +
						'    accent_color\n' +
						'    primary_color\n' +
						'    logo {\n' +
						'      title\n' +
						'      alt\n' +
						'      file {\n' +
						'        sources {\n' +
						'          original\n' +
						'          thumbnail\n' +
						'          large\n' +
						'        }\n' +
						'      }\n' +
						'    }\n' +
						'    settings {\n' +
						'      booleans {\n' +
						'        key\n' +
						'        value\n' +
						'      }\n' +
						'      strings {\n' +
						'        key\n' +
						'        value\n' +
						'      }\n' +
						'      stringLists {\n' +
						'        key\n' +
						'        value\n' +
						'      }\n' +
						'      integers {\n' +
						'        key\n' +
						'        value\n' +
						'      }\n' +
						'    }\n' +
						'  }\n' +
						'  paymentMethodInstanceList(organization: "' + state.organizationUUID + '", terminal: true) {\n' +
						'    namespace\n' +
						'    icon\n' +
						'    title\n' +
						'    description\n' +
						'    uuid\n' +
						'    checkout\n' +
						'    alertMessage\n' +
						'    uiFields {\n' +
						'      key\n' +
						'      label\n' +
						'      type\n' +
						'      cols\n' +
						'      value\n' +
						'      required\n' +
						'      options {\n' +
						'       key\n' +
						'       label\n' +
						'      }\n' +
						'     }\n' +
						'  }\n' +
						'  checkoutSectionInstanceList(organization: "' + state.organizationUUID + '", channel: "' + state.channelUUID + '") {\n' +
						'    title\n' +
						'    description\n' +
						'    namespace\n' +
						'    step\n' +
						'    data\n' +
						'    weight\n' +
						'    uiFields {\n' +
						'      key\n' +
						'      label\n' +
						'      type\n' +
						'      value\n' +
						'      required\n' +
						'      options {\n' +
						'       key\n' +
						'       label\n' +
						'      }\n' +
						'    }\n' +
						'  }\n' +
						'  checkoutSectionList(organization: "' + state.organizationUUID + '") {\n' +
						'    title\n' +
						'    description\n' +
						'    namespace\n' +
						'    uiFields {\n' +
						'      key\n' +
						'      label\n' +
						'      type\n' +
						'    }\n' +
						'  }\n' +
						'  checkoutStepList(organization: "' + state.organizationUUID + '") {\n' +
						'    title\n' +
						'    description\n' +
						'    namespace\n' +
						'  }\n' +
						'  systemTimeInMillis\n' +
						'}\n'
				}
			}

			return axios.post(state.graphUrl, query).then(response => {

				if (response.data.data) {
					commit('updateChannel', response.data.data.channel)
					commit('updateOrganization', response.data.data.organization)

					// TODO: Handle organization settings regarding analytics codes
					if (state.organization.uuid === '4e507c89-2141-408a-9878-a8dcdb5b0cec') { //Smárabíó
						commit('setGtagCode', 'AW-974172793')
						commit('setFbPixelCode', '403588070044508')
					}
					if (state.organization.uuid === '49702bca-27b3-4860-8bb0-cfd4bcb138eb') { //Arena Gaming
						commit('setFbPixelCode', '1038842746880063')
					}

					let checkoutSectionInstanceList = response.data.data.checkoutSectionInstanceList
					checkoutSectionInstanceList.forEach(sectionInstance => {
						if (sectionInstance.data) {
							try {
								sectionInstance.data = JSON.parse(sectionInstance.data)
							} catch (e) {
								//
							}
						}
					})
					commit('updateCheckoutSectionInstances', checkoutSectionInstanceList)
					commit('updateCheckoutSections', response.data.data.checkoutSectionList)
					commit('updatePaymentMethods', response.data.data.paymentMethodList)
					commit('updateCheckoutSteps', response.data.data.checkoutStepList)
					commit('updateSystemTimeInMillis', response.data.data.systemTimeInMillis)
					commit('setItemsList', response.data.data.itemsList)
					if (Array.isArray(response.data.data.deliveryServices)) {
						commit('setDeliveryServices', response.data.data.deliveryServices.filter(service => service.enabled === true))
					}

					let paymentMethodInstancesList = response.data.data.paymentMethodInstanceList

					//HARDCODE for Andri Iceland to get rid of valitor_fyrirtaekjagreidslur from new checkout while still keeping it in the legacy checkout.
					if (response.data.data.organization !== null && response.data.data.organization.uuid === '9d5e2cba-35b1-474c-beaf-f74f970f8bee') {
						let instancesArr = []

						paymentMethodInstancesList.forEach(instance => {
							if (instance.namespace !== 'valitor_fyrirtaekjagreidslur') {
								instancesArr.push(instance)
							}
						})

						paymentMethodInstancesList = instancesArr
					}

					if (Array.isArray(paymentMethodInstancesList) && paymentMethodInstancesList.length > 0) {
						const paymentMethodInstances = []
						for (let instanceIndex = 0; instanceIndex < paymentMethodInstancesList.length; instanceIndex++) {
							const paymentMethod = paymentMethodInstancesList[instanceIndex]

							if (typeof state.channel !== 'undefined' && state.channel !== null && Array.isArray(state.channel.acceptedPaymentMethodInstances) && state.channel.acceptedPaymentMethodInstances.length > 0) {
								if (!state.channel.acceptedPaymentMethodInstances.some(paymentInstance => paymentInstance.uuid === paymentMethod.uuid)) {
									continue
								}
							}
							paymentMethodInstances.push(paymentMethod)
						}
						paymentMethodInstancesList = paymentMethodInstances
					}

					commit('updatePaymentMethodInstances', paymentMethodInstancesList)

					const checkForCouponSectionInstance = response.data.data.checkoutSectionInstanceList.filter(i => i)
					if (checkForCouponSectionInstance.some(instance => instance.namespace === 'coupon')) {
						commit('setShowCoupon', true)
					}

					let organization = response.data.data.organization
					if (organization && organization.config) {
						let ids = organization.config.filter(c => c.key === 'analytics')
						commit('updateAnalyticsIDs', ids)
					}

					if (state.analyticsIDs !== null && state.analyticsIDs !== undefined && state.analyticsIDs.length) {
						let first = state.analyticsIDs[0]
						let rest = state.analyticsIDs.slice(1).map(id => {
							return {
								id: id
							}
						})
						// eslint-disable-next-line no-console

						/* GTAG SET GTAG IDS START */
						setOptions({
							config: {id: first}
						})
						if (rest !== null && rest !== undefined) {
							setOptions({
								includes: rest
							})
						}
						/* GTAG SET GTAG IDS END */
					}
				}
			}).catch((error) => {
				Bugsnag.notify(error, (event) => {
					event.context = 'Axios Error On Getting Initial Data [graph][index.js]'
				})
			})
		},
		// eslint-disable-next-line no-empty-pattern
		getBalance({}, balanceRequest) {
			const url = balanceRequest.url
			const payload = balanceRequest.payload

			return axios.post(url, payload).then(result => {
				if (result && result.data) {
					return result.data
				}
				return null
			}).catch((error) => {
				Bugsnag.notify(error, (event) => {
					event.context = 'Axios Error On Getting Balance [index.js]'
				})
				return null
			})
		}
	},
	getters: {
		balanceRequest: (state) => (instance) => {
			const request = {}
			request.url = `${state.paymentRequestUrl}/${state.organizationUUID}/${state.channelUUID}/${instance.uuid}/balance`
			request.payload = {}

			return request
		},
		getOrderLineItems: (state) => {
			const order = state.order
			const lineItems = []

			if (order && Array.isArray(order.lineItems)) {
				for (let index = 0; index < order.lineItems.length; index++) {
					const lineItem = order.lineItems[index]
					if (lineItem) {
						const itemUUID = (lineItem && lineItem.item && lineItem.item.uuid) ? lineItem.item.uuid : ''
						const lineItemVariations = (Array.isArray(lineItem.variations) && lineItem.variations.length > 0) ? lineItem.variations.map(v => v.uuid) : []
						lineItems.push({
							item: itemUUID,
							quantity: lineItem.quantity,
							variations: lineItemVariations
						})
					}
				}
			}
			return lineItems
		},
		location: (state) => {
			return state.order.location
		},
		bookingOnly: (state) => {
			return state.order && state.order.lineItems && state.order.lineItems.length === 1 && (!state.order.lineItems[0].unitPrice || (state.order.lineItems[0].unitPrice && state.order.lineItems[0].unitPrice.amount === 0))
		},
		organization: (state) => {
			return state.organization
		},
		paymentMethodInstances: (state) => {
			return state.paymentMethodInstances
		},
		zeroAmountCheckoutSection: () => {
			return {
				checkout: true,
				description: 'Engin greiðsla',
				icon: null,
				namespace: 'complete',
				title: 'Staðfesta',
				uuid: 'complete',
				uiFields: [
					{
						cols: 12,
						key: "complete_payment",
						label: "Klára",
						options: null,
						required: false,
						type: 'html',
						value: '🎉 Vó, þú þarft ekkert að borga! 🎉 Ýttu á takkann hér að neðan til að klára.👇',
					}
				]
			}
		},
		selectedPaymentMethodInstance: (state) => {
			const index = state.selectedPaymentMethodInstanceIndex

			if (index !== null && index !== undefined && state.paymentMethodInstances !== null && state.paymentMethodInstances !== undefined && state.paymentMethodInstances.length >= index) {
				return state.paymentMethodInstances[index]
			}

			return null
		},
		isSelectedPaymentMethodInstanceValid: (state, getters) => {
			const selectedPaymentMethodInstance = getters.selectedPaymentMethodInstance

			// We are optimistic because the Payment Request will be declined if not valid
			let isValid = true

			if ((getters.order.orderTotal === 0 || getters.order.orderTotal === null) && ((selectedPaymentMethodInstance === null || selectedPaymentMethodInstance === undefined))) {
				return isValid
			}

			if (selectedPaymentMethodInstance === null || selectedPaymentMethodInstance === undefined) {
				return false
			}

			if (selectedPaymentMethodInstance.uiFields === null || selectedPaymentMethodInstance === undefined || selectedPaymentMethodInstance.uiFields.length === 0) {
				return isValid
			}

			for (let i in selectedPaymentMethodInstance.uiFields) {
				const uiField = selectedPaymentMethodInstance.uiFields[i]

				if (uiField.required && (uiField.value === null || uiField.value === undefined || uiField.value === '')) {
					isValid = false
				}
			}

			return isValid
		},
		billingInfo: (state) => {

			const billingInfo = {
				name: null,
				phone: null,
				email: null,
				address: null,
				municipality: null,
				postal_code: null,
				country: null,
			}

			if (state.checkoutSectionInstances) {
				for (let i in state.checkoutSectionInstances) {
					if (state.checkoutSectionInstances[i].namespace === 'billing_minimal' || state.checkoutSectionInstances[i].namespace === 'customer_profile_billing') {
						for (let n in state.checkoutSectionInstances[i].uiFields) {
							const uiField = state.checkoutSectionInstances[i].uiFields[n]
							const key = uiField.key
							const value = uiField.value
							billingInfo[key] = value
						}
					}
				}
			}

			return billingInfo
		},
		shippingInfo: (state) => {

			let shippingInfo = {
				billing_is_shipping: null,
				name: null,
				phone: null,
				email: null,
				address: null,
				municipality: null,
				postal_code: null,
				country: null
			}
			if (state.checkoutSectionInstances) {
				for (let i in state.checkoutSectionInstances) {
					if (state.checkoutSectionInstances[i].namespace === 'customer_profile_shipping') {
						if (state.checkoutSectionInstances[i].uiFields[0].value === true || state.checkoutSectionInstances[i].uiFields[0].value === 'true') {
							return {billing_is_shipping: true}
						}
						for (let n in state.checkoutSectionInstances[i].uiFields) {
							const uiField = state.checkoutSectionInstances[i].uiFields[n]
							const key = uiField.key
							const value = uiField.value
							shippingInfo[key] = value
						}
					}
				}
			}
			return shippingInfo
		},
		orderComment: (state) => {
			if (state.checkoutSectionInstances) {
				for (let i in state.checkoutSectionInstances) {
					if (state.checkoutSectionInstances[i].namespace === 'order_comment') {
						return state.checkoutSectionInstances[i].uiFields[0].value
					}
					if (state.checkoutSectionInstances[i].namespace === 'ticket_invitee') {
						return state.checkoutSectionInstances[i].uiFields[0].value
					}
				}
			}
		},
		paymentDetails: (state, getters) => {
			const details = {}

			const selectedPaymentMethodInstance = getters.selectedPaymentMethodInstance

			if (selectedPaymentMethodInstance === null || selectedPaymentMethodInstance === undefined) {
				return details
			}

			if (selectedPaymentMethodInstance.uiFields === null || selectedPaymentMethodInstance.uiFields === undefined || selectedPaymentMethodInstance.uiFields.length === 0) {
				return details
			}

			for (let i in selectedPaymentMethodInstance.uiFields) {
				const uiField = selectedPaymentMethodInstance.uiFields[i]
				const key = uiField.key
				const value = uiField.value
				details[key] = value
			}

			return details
		},
		entangleKey: (state) => {
			const entangleKey = null

			if (state.order && state.order.entangleKey) {
				return state.order.entangleKey
			}

			return entangleKey
		},
		paymentRequest: (state, getters) => {

			let totalPriceToPay = getters.orderTotal
			const billingInfo = getters.billingInfo
			const shippingInfo = getters.shippingInfo
			const paymentDetails = getters.paymentDetails
			const orderComment = getters.orderComment
			const entangleKey = getters.entangleKey
			const selectedPaymentMethodInstance = getters.selectedPaymentMethodInstance
			let coupon = null

			if (getters.hasActiveCoupon) {
				coupon = getters.activeCoupon.uuid
			} else if (totalPriceToPay.amount === 0) {
				// Way to by pass null-check on coupon in Service Payment Request. It was decided that simply removing or changing the null-check was to risky.
				// service-payment-request -> if(paymentRequest.amount === 0 && couponUUID) { * Proceed with payment * }
				coupon = '0'
			}

			const requestUUID = uuidv4()

			const requestId = `CH-${requestUUID}`

			// Smárabíó confirmation fee
			if (getters.isSmarabioHopaPontun) {
				let firstOrderLineItem = null
				let confirmationFeeAmount = getters.smarabioConfirmationFee()

				if (typeof state.order !== 'undefined' && state.order !== null) {
					if (Array.isArray(state.order.lineItems) && state.order.lineItems.length > 0) {
						firstOrderLineItem = state.order.lineItems[0]
					}
				}
				if (firstOrderLineItem) {
					confirmationFeeAmount = getters.smarabioConfirmationFee(firstOrderLineItem.quantity)
				}

				totalPriceToPay = {amount: confirmationFeeAmount, currency_code: 'ISK'}
			}

			const paymentRequest = {
				namespace: selectedPaymentMethodInstance.namespace,
				requestId: requestId,
				entangleKey: entangleKey,
				amount: totalPriceToPay.amount,
				currency_code: totalPriceToPay.currency_code,
				isApplePay: false,
				details: paymentDetails,
				billingInfo: billingInfo,
				shippingInfo: shippingInfo,
				comment: orderComment,
				coupon: coupon,
				deliveryService: state.selectedDeliveryService ? state.selectedDeliveryService : null,
				deliveryServicePrice: state.selectedDeliveryServicePrice ? state.selectedDeliveryServicePrice : null,
				checkoutSectionInstancesData: state.checkoutSectionInstancesData
			}

			// Handling dependent payments
			let dependentPayments = [...state.dependentPayments]
			if (Array.isArray(dependentPayments) && dependentPayments.length > 0) {
				paymentRequest.dependentPayments = dependentPayments
				if (paymentRequest.amount === 0 && selectedPaymentMethodInstance.uuid === paymentRequest.dependentPayments[0].paymentMethodInstanceUUID) {
					paymentRequest.amount = paymentRequest.dependentPayments[0].amount
					paymentRequest.dependentPayments.splice(0, 1)
				}
			}
			return paymentRequest
		},
		showPaymentPending: (state) => {
			return state.paymentIsPending
		},
		originalOrderTotal: (state, getters) => {

			let totalPrice = {
				amount: 0,
				currency_code: 'ISK'
			}

			for (let i in state.order.lineItems) {

				totalPrice.currency_code = state.order.lineItems[i].unitPrice.currency_code
				let itemPriceAmount = state.order.lineItems[i].unitPrice.amount * state.order.lineItems[i].quantity

				if (getters.hasActiveCoupon) {
					itemPriceAmount = Math.round(itemPriceAmount - getters.couponLineItemDiscount(state.order.lineItems[i]))
				}

				totalPrice.amount += itemPriceAmount
			}

			if (state.selectedDeliveryService && state.selectedDeliveryServicePrice) {

				if (state.selectedDeliveryServicePrice.amount > 0) {
					totalPrice.amount += state.selectedDeliveryServicePrice.amount
				}
			}

			return totalPrice
		},
		orderTotal: (state, getters) => {

			let totalPrice = {
				amount: 0,
				currency_code: 'ISK'
			}

			if(!state.order || !Array.isArray(state.order.lineItems)) {
				return totalPrice
			}

			for (let i in state.order.lineItems) {

				totalPrice.currency_code = state.order.lineItems[i].unitPrice.currency_code
				let itemPriceAmount = state.order.lineItems[i].unitPrice.amount * state.order.lineItems[i].quantity

				if (getters.hasActiveCoupon) {
					itemPriceAmount = Math.round(itemPriceAmount - getters.couponLineItemDiscount(state.order.lineItems[i]))
				}

				totalPrice.amount += itemPriceAmount
			}

			if (state.selectedDeliveryService && state.selectedDeliveryServicePrice) {

				if (state.selectedDeliveryServicePrice.amount > 0) {
					totalPrice.amount += state.selectedDeliveryServicePrice.amount
				}
			}

			if (Array.isArray(state.dependentPayments)) {
				for (let dpIndex = 0; dpIndex < state.dependentPayments.length; dpIndex++) {
					const dependentPayment = state.dependentPayments[dpIndex]
					if (dependentPayment) {
						totalPrice.amount -= dependentPayment.amount
					}
				}
			}

			return totalPrice
		},
		orderContainsItemByUUID: (state) => (itemUUID) => {
			const index = state.order.lineItems.findIndex(l => {
				return l.item.uuid === itemUUID
			})
			return index !== -1
		},
		orderTotalWithoutDiscounts: (state) => {

			let totalPrice = {
				amount: 0,
				currency_code: 'ISK'
			}

			for (let i in state.order.lineItems) {
				let itemPriceAmount = state.order.lineItems[i].unitPrice.amount * state.order.lineItems[i].quantity
				totalPrice.amount += itemPriceAmount
			}

			return totalPrice
		},
		cartItemsForAnalytics: (state) => {
			let cart = []
			if (state.order !== null && state.order !== undefined
				&& state.order.lineItems !== null && state.order.lineItems !== undefined
				&& state.order.lineItems.length) {
				state.order.lineItems.forEach((lineItem, index) => {
					let itemCategories = null
					if (lineItem.item !== null && lineItem.item !== undefined) {
						if (Array.isArray(lineItem.item.categories) && lineItem.item.categories.length > 0) {
							itemCategories = lineItem.item.categories.map(category => category.name).join('|')
						}

						let cartItem = {
							id: lineItem.item.uuid,
							sku: lineItem.item.sku,
							name: lineItem.line_item_label,
							category: itemCategories,
							variant: lineItem.dynamicDescription,
							list_position: index,
							quantity: lineItem.quantity,
							price: lineItem.unitPrice.amount
						}
						cart.push(cartItem)
					}

				})
			}
			return cart
		},
		lineItemHasCustomPrice: () => (lineItem) => {
			if (lineItem && lineItem.uiValues) {
				for (let i in lineItem.uiValues) {
					if (i === 'custom_amount') {
						return lineItem.uiValues[i].value
					}
				}
			} else {
				return null
			}
		},
		validateBeforePayment: () => {
			//
		},
		lineItemHasBookings: () => (lineItem) => {
			return !!(lineItem.bookings && lineItem.bookings.length)
		},
		getBreadcrumbs: (state, getters) => {
			const breadcrumbs = []
			const checkoutSteps = getters.getCheckoutSteps

			for (let i in checkoutSteps) {
				let checkoutStep = checkoutSteps[i]
				// No breadcrumbs if no order
				if(!state.order?.uuid) {
					continue
				}

				const route = '/' + state.organizationUUID + '/' + state.channelUUID + '/' + state.order.uuid + '/' + checkoutStep.namespace

				breadcrumbs.push({
					disabled: true,
					exact: true,
					link: true,
					text: i18n.t(checkoutStep.title),
					to: route
				})
			}

			return breadcrumbs
		},
		getCheckoutSection: (state) => (namespace) => {

			for (let i in state.checkoutSections) {
				if (state.checkoutSections[i].namespace === namespace) {
					return state.checkoutSections[i]
				}
			}

			return null
		},
		getPaymentMethod: (state) => (namespace) => {

			for (let i in state.paymentMethods) {
				if (state.paymentMethods[i].namespace === namespace) {
					return state.paymentMethods[i]
				}
			}

			return null
		},
		getCheckoutSteps: (state) => {
			const checkoutStepIndex = {}
			const checkoutSectionInstances = state.checkoutSectionInstances

			for (let i in checkoutSectionInstances) {
				checkoutStepIndex[checkoutSectionInstances[i].step] = checkoutSectionInstances[i].step
			}

			const checkoutSteps = []

			for (let i in state.checkoutSteps) {
				if (checkoutStepIndex[state.checkoutSteps[i].namespace]) {
					checkoutSteps.push(state.checkoutSteps[i])
				}
			}

			return checkoutSteps
		},
		getCheckoutStep: (state) => (namespace) => {
			for (let i in state.checkoutSteps) {
				if (state.checkoutSteps[i].namespace === namespace) {
					return state.checkoutSteps[i]
				}
			}

			return null
		},
		getNextCheckoutStep: (state, getters) => (namespace) => {

			const checkoutSteps = getters.getCheckoutSteps

			for (let i in checkoutSteps) {
				if (checkoutSteps[i].namespace === namespace) {
					// eslint-disable-next-line no-console
					return checkoutSteps[parseInt(i) + 1]
				}
			}

			return null
		},
		getPreviousCheckoutStep: (state, getters) => (namespace) => {

			const checkoutSteps = getters.getCheckoutSteps

			for (let i in checkoutSteps) {
				if (checkoutSteps[i].namespace === namespace) {
					return checkoutSteps[parseInt(i) - 1]
				}
			}

			return null
		},
		getCheckoutSectionInstances: (state) => (step) => {
			const checkoutSections = []

			for (let i in state.checkoutSectionInstances) {

				if (state.checkoutSectionInstances[i].namespace === 'cart_contents') {
					continue
				}

				if (state.checkoutSectionInstances[i].namespace === 'checkout_review') {
					continue
				}

				if (state.checkoutSectionInstances[i].namespace === 'commerce_payment_redirect') {
					continue
				}

				if (state.checkoutSectionInstances[i].step === step) {
					checkoutSections.push(state.checkoutSectionInstances[i])
				}
			}
			return checkoutSections
		},
		canCheckoutOrder: (state, getters) => {
			let canCheckoutOrder = true

			if (getters.isCartEmpty) {
				return false
			}

			if (state.progressingOrder || state.deletingLineItem) {
				canCheckoutOrder = false
			}

			return canCheckoutOrder
		},
		primaryColor: (state) => {
			return state.organization.primary_color
		},
		canDeleteLineItem: (state) => (lineItem) => {
			let canDeleteLineItem = true

			if (lineItem.locked) {
				canDeleteLineItem = false
			}

			if (state.deletingLineItem) {
				canDeleteLineItem = false
			}

			return canDeleteLineItem
		},
		isInitialized: (state) => {
			let isInitialized = false

			if (typeof state.sessionToken !== 'undefined' && state.sessionToken !== null) {
				if (typeof state.anonymousSession !== 'undefined' && state.anonymousSession !== null) {
					if (typeof state.organization !== 'undefined' && state.organization !== null) {
						isInitialized = true
					}
				}
			}

			return isInitialized
		},
		isCartEmpty: (state) => {
			let isCartEmpty = true

			if (state.order !== null && state.order !== undefined && state.order.lineItems !== null && state.order.lineItems !== undefined) {
				isCartEmpty = false
			}

			return isCartEmpty
		},
		months: () => (selectedYear) => {
			let months = [...Array(12).keys()].map(i => (i + 1).toString().padStart(2, '0'))
			const currentDate = new Date()
			const currentYear = currentDate.getFullYear()
			const currentMonth = currentDate.getMonth()
			if (currentYear === selectedYear) {
				months = months.slice(currentMonth)
			}
			return months
		},
		years: () => {
			const currentYear = new Date().getFullYear()
			return [...Array(10).keys()].map(i => (i + currentYear).toString())
		},
		organizationUUID: state => {
			return state.organizationUUID
		},
		graphUrl: state => {
			return state.graphUrl
		},
		order: state => {
			return state.order
		},
		allTermsAgreed: (state) => {
			const checkoutSectionInstances = state.checkoutSectionInstances
			if (checkoutSectionInstances.length > 0) {
				for (var i = 0; i < checkoutSectionInstances.length; i++) {
					if (checkoutSectionInstances[i].namespace === 'terms_and_conditions') {
						return state.termsAgreedTo
					}
				}
				return true
			} else {
				return true
			}
		},
		gettingOrderBookings: (state) => {
			return state.gettingOrderBookings
		},
		orderBookings: (state) => {
			return state.orderBookings
		},
		gettingOrderSubscriptions: (state) => {
			return state.gettingOrderSubscriptions
		},
		orderSubscriptions: (state) => {
			return state.orderSubscriptions
		},
		selectedDeliveryServicePrice: (state) => {
			return state.selectedDeliveryServicePrice
		}
	},
	modules: {
		coupon,
		analytics,
		hardcode
	}
})
