export default {
    state: {
        smarabioOrganizationUUID: '4e507c89-2141-408a-9878-a8dcdb5b0cec',
        smarabioHopaPantanirCategory: '4b8a49a9-a96d-4c9e-9a64-426104571b3c',
        itemsList: []
    },
    mutations: {
        setItemsList(state, list) {
            state.itemsList = list
        }
    },
    actions: {},
    getters: {
        isSmarabio: (state, getters) => {
            return getters.organizationUUID === state.smarabioOrganizationUUID
        },
        isSmarabioHopaPontun: (state, getters) => {
            const order = getters.order
            const lineItems = order ? order.lineItems : []
            if (getters.isSmarabio) {
                if (Array.isArray(lineItems) && lineItems.length > 0) {
                    for (let i = 0; i < lineItems.length; i++) {
                        const lineItem = lineItems[i]
                        if (!lineItem || !lineItem.item || !Array.isArray(state.itemsList) || state.itemsList.length < 1) {
                            continue
                        }
                        const itemUUID = lineItem.item.uuid
                        const foundItem = state.itemsList.find(item => item && item.uuid === itemUUID)
                        if (foundItem) {
                            if (Array.isArray(foundItem.categories) && foundItem.categories.length > 0) {
                                if (foundItem.categories.some(category => category && category.uuid === state.smarabioHopaPantanirCategory)) {
                                    return true
                                }
                            }
                        }
                    }
                }
            }
            // return getters.organizationUUID === state.smarabioOrganizationUUID && Array.isArray(lineItems) && lineItems.length > 0 && lineItems.some(li => li && Array.isArray(li.categories) && li.categories.some(c => c.uuid === state.smarabioBirthdayCategory))
            return false
        },
        smarabioConfirmationFee: (state, getters) => (quantity) => {
            if (getters.isSmarabioHopaPontun) {
                if (quantity >= 30) {
                    return 1
                    // return 20000
                }
                return 1
                // return 10000
            }
            return 0
        },
        itemsList: (state) => {
            return state.itemsList
        }
    }
}
