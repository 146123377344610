export default {
	state: {
		gtagCode: null,
		gaCode: null,
		fbPixelCode: null
	},
	mutations: {
		setGtagCode(state, code) {
			state.gtagCode = code
		},
		setGaCode(state, code) {
			state.gaCode = code
		},
		setFbPixelCode(state, code) {
			state.fbPixelCode = code
		}
	},
	actions: {},
	getters: {
		hasGoogleAnalytics: (state) => {
			return !!(state.gaCode || state.gtagCode)
		},
		hasFacebookAnalytics: (state) => {
			return !!state.fbPixelCode
		},
		gtagCode: (state) => {
			return state.gtagCode
		},
		gaCode: (state) => {
			return state.gaCode
		},
		fbPixelCode: (state) => {
			return state.fbPixelCode
		},
		checkoutItems: (state, getters) => {
			const cart = getters.order
			let cartLineItems = (cart && cart.lineItems && Array.isArray(cart.lineItems)) ? cart.lineItems : []

			let checkoutItems = []
			for (let i = 0; i < cartLineItems.length; i++) {
				let lineItem = cartLineItems[i]
				let itemCategories = null
				if (lineItem.item && Array.isArray(lineItem.item.categories)) {
					itemCategories = lineItem.item.categories.map(category => category.name).join('|')
				}
				let item = {
					id: lineItem.item ? lineItem.item.uuid : 'NO12345678',
					sku: lineItem.item ? lineItem.item.sku : 'no12345678',
					name: lineItem.item ? lineItem.item.title : 'No 12345678',
					variant: lineItem.dynamicDescription,
					quantity: lineItem.quantity,
					price: lineItem.unitPrice.amount,
					currency: lineItem.unitPrice.currency_code
				}

				if (itemCategories) {
					item.category = itemCategories
				}

				if (state.activeCoupon && getters.couponLineItemDiscount(lineItem) > 0) {
					item.coupon = state.activeCoupon.code
					//item.discount = getters.couponLineItemDiscount(lineItem)
					item.discount = lineItem.unitPrice.amount - Math.round(lineItem.unitPrice.amount - getters.couponLineItemDiscount(state.order.lineItems[i]))
				}

				checkoutItems.push(item)
			}

			return checkoutItems
		},
		checkoutObject: (state, getters) => {
			const checkoutObject = {}

			const checkoutItems = getters.checkoutItems
			const coupon = getters.activeCoupon

			if (Array.isArray(checkoutItems) && checkoutItems.length > 0) {
				checkoutObject.items = checkoutItems
			}

			if (coupon) {
				checkoutObject.coupon = coupon.code
			}

			checkoutObject.value = getters.checkoutValue
			checkoutObject.currency = getters.checkoutCurrency

			return checkoutObject
		},
		paymentObject: (state, getters) => {
			const paymentObject = {}
			paymentObject.value = getters.checkoutValue
			paymentObject.currency = getters.checkoutCurrency
			paymentObject.payment_type = getters.selectedPaymentMethodInstance.title // namespace rather than title?

			paymentObject.items = getters.checkoutItems

			const coupon = getters.activeCoupon
			if (coupon) {
				paymentObject.coupon = coupon.code
			}
		},
		purchaseObject: () => (paymentRequestResponse) => {
			let purchaseObject = {}

			purchaseObject.transaction_id = paymentRequestResponse.paymentTransaction.uuid
			purchaseObject.value = paymentRequestResponse.paymentTransaction.amount + '.00'
			purchaseObject.currency = paymentRequestResponse.paymentTransaction.currency_code

			return purchaseObject
		},
		fbPurchaseObject: (state, getters) => (paymentResponse) => {
			let purchaseObject = {}

			purchaseObject.value = paymentResponse.amount
			purchaseObject.currency = paymentResponse.currency_code
			purchaseObject.content_type = 'product'
			purchaseObject.content_name = getters.organization.label
			purchaseObject.num_items = getters.checkoutItems.length
			purchaseObject.content_ids = getters.checkoutItems.map(item => item.sku)

			purchaseObject.contents = getters.checkoutItems.map(item => {
				return {
					id: item.id,
					name: item.name,
					quantity: item.quantity,
					price: item.price
				}
			})

			return purchaseObject
		},
		fbCheckoutObject: (state, getters) => {
			let checkoutObject = {}

			checkoutObject.content_type = 'product'
			checkoutObject.num_items = getters.checkoutItems.length
			checkoutObject.content_ids = getters.checkoutItems.map(item => item.sku)
			checkoutObject.contents = getters.checkoutItems.map(item => {
				return {
					id: item.id,
					name: item.name,
					quantity: item.quantity,
					price: item.price,
					currency: item.currency
				}
			})

			checkoutObject.currency = getters.checkoutCurrency
			checkoutObject.value = getters.checkoutValue

			return checkoutObject
		},
		checkoutValue: (state, getters) => {
			const checkoutItems = getters.checkoutItems
			let value = 0
			for (let i = 0; i < checkoutItems.length; i++) {
				const checkoutItem = checkoutItems[i]
				let checkoutItemPrice = checkoutItem.price
				if (checkoutItem.discount && checkoutItem.discount > 0) {
					checkoutItem.price = checkoutItem.price - checkoutItem.discount
				}
				value += (checkoutItemPrice * checkoutItem.quantity)
			}
			return value
		},
		checkoutCurrency: (state, getters) => {
			const checkoutItems = getters.checkoutItems
			// We filter to prevent null, undefined and empty string values
			const currencies = checkoutItems.map(item => item.currency).filter(currency => currency && currency !== '')
			const condensed = Array.from(new Set(currencies))
			if (condensed.length < 1) {
				// no currencies
				return []
			} else if (condensed.length === 1) {
				// only one currency (which it should always be)
				return condensed[0]
			} else {
				// if there are multiple currencies (something we do not want there to be)
				// we COULD do a loop through all currencies and count each and only use the most counted one
				return condensed[0]
			}
		}
	}
}