<template>
  <Error title="Incomplete Link" description="You have reached the checkout with an incomplete link"></Error>
</template>

<script>

  import Error from "../components/Error";

  export default {
    name: 'home',
    components: {
      Error
    }
  }
</script>
