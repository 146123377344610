<template>
  <v-container fill-height>
    <v-row align="center" justify="center">
      <v-col cols="12">
        <v-alert
                prominent
                type="error"
        >
          <div class="title">{{ title }}</div>
          <div class="subtitle-1">{{ description }}</div>
        </v-alert>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'Alert',
  props: ['title', 'description'],
  data: () => ({

  }),
};
</script>
