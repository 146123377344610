<template>
  <v-container class="pt-0 pb-0" fill-height>
		<v-row align="start" justify="start" class="fill-height pt-0 pb-0">
			<v-col order-md="0" order-sm="1" order="1" md="6" sm="12" cols="12">
        <v-skeleton-loader
          type="card"
          class="mx-auto"
         />
         <v-skeleton-loader
          type="card"
          class="mx-auto"
         />
			</v-col>
			<v-col order-md="1" order-sm="0" order="0" class="grey lighten-3 pt-0 pb-0"
					:class="!mobile ? 'fill-height':''" md="6" sm="12" cols="12">
			</v-col>
		</v-row>
	</v-container>
</template>

<script>

  export default {
    name: 'CartSkeleton',
    props: ['order'],
    data: () => ({}),
    methods: {
      checkoutOutOrder: function () {
        this.$store.dispatch('progressOrder')
      }
    },
    computed: {
      mobile: function () {

        switch (this.$vuetify.breakpoint.name) {
          case 'xs':
            return true
          case 'sm':
            return true
          case 'md':
            return false
          case 'lg':
            return false
          case 'xl':
            return false
        }
        
        // eslint-disable-next-line no-mixed-spaces-and-tabs
			  return false
      },
      canCheckoutOrder: function() {
        return this.$store.getters.canCheckoutOrder
      },
      isProgressingOrder: function() {
        return this.$store.state.progressingOrder
      },
      isCartEmpty: function () {
        return this.$store.getters.isCartEmpty
      }
    }
  };
</script>
