<template>
  <v-app app data-iframe-height :class="inIframe ? 'inIframe' : ''">
    <v-main>
      <v-dialog max-width="500px" v-model="show3DSecureDialog" persistent>
        <v-card>
          <iframe
            seamless
            style="border: 0;"
            width="100%"
            height="500px"
            :srcdoc="rawHTMLFor3DSecure"
          />
        </v-card>
      </v-dialog>
      <router-view v-if="isInitialized"/>
      <CartSkeleton v-else/>
    </v-main>
  </v-app>
</template>

<script>
import CartSkeleton from "./components/CartSkeleton";
import {bootstrap, setOptions} from "vue-gtag";
import {version} from "../package.json";

export default {
  name: "app",
  components: {
    CartSkeleton
  },
  data() {
    return {
      smarabioIsInitialized: false,
      show3DSecureDialog: false
    };
  },
  watch: {
    show3DSecureDialog(newValue, oldValue) {
      if (
        newValue === false &&
        oldValue === true &&
        this.paymentResponse !== null &&
        this.paymentResponse !== undefined
      ) {
        if (
          this.paymentResponse.paymentTransaction !== null &&
          this.paymentResponse.paymentTransaction !== undefined &&
          this.paymentResponse.paymentTransaction.payload !== null &&
          this.paymentResponse.paymentTransaction.payload !==
          undefined &&
          this.paymentResponse.paymentTransaction.payload.mdStatus !==
          null &&
          this.paymentResponse.paymentTransaction.payload.mdStatus !==
          undefined &&
          this.paymentResponse.paymentTransaction.payload.mdStatus ===
          "1"
        ) {
          const paymentRequest = this.$store.getters.paymentRequest
          paymentRequest.details.skipCardVerification = true;

          paymentRequest.details.cavv = this.paymentResponse.paymentTransaction.payload.cavv;
          paymentRequest.details.xid = this.paymentResponse.paymentTransaction.payload.xid;
          paymentRequest.details.mdStatus = this.paymentResponse.paymentTransaction.payload.mdStatus;
          paymentRequest.details.dsTransId = this.paymentResponse.paymentTransaction.payload.dsTransId;

          const payload = {
            selectedPaymentMethodInstance: this.$store.getters
              .selectedPaymentMethodInstance,
            paymentRequest: paymentRequest
          };

          this.show3DSecureDialog = false;
          this.$store.dispatch("processPayment", payload);
        } else {
          this.show3DSecureDialog = false;
        }
      }
    },
    // analyticsIDs: function(ids) {
    // 	if(ids !== null && ids !== undefined && ids.length) {
    // 		let first = ids[0].value
    // 		let rest = ids.slice(1, -1).map(id => {
    // 			return {
    // 				id: id.value
    // 			}
    // 		})
    //
    // 		/* GTAG SET GTAG IDS START */
    // 		// setOptions({
    // 		// 	config: {id: first}
    // 		// })
    // 		// if(rest !== null && rest !== undefined) {
    // 		// 	setOptions({
    // 		// 		includes: rest
    // 		// 	})
    // 		// }
    // 		/* GTAG SET GTAG IDS END */
    //
    // 		/* GTAG BEGIN CHECKOUT START */
    // 		this.$gtag.event('begin_checkout', {'items': this.items})
    // 		/* GTAG BEGIN CHECKOUT END */
    // 	}
    // },
    paymentResponse(paymentResponse) {
      if (
        paymentResponse !== null &&
        paymentResponse.paymentTransaction !== null &&
        paymentResponse.paymentTransaction !== undefined &&
        paymentResponse.paymentTransaction.payload !== null &&
        paymentResponse.paymentTransaction.payload !== undefined &&
        paymentResponse.paymentTransaction.payload["3DSecureHTML"] !==
        undefined &&
        paymentResponse.paymentTransaction.payload["3DSecureHTML"] !==
        undefined &&
        paymentResponse.paymentTransaction.status === "pending"
      ) {
        setTimeout(() => {
          this.show3DSecureDialog = true;
        }, 2500);
      } else {
        this.show3DSecureDialog = false;
      }
    },
    channelUUID: function (newValue) {
      if (
        newValue !== null &&
        newValue !== undefined &&
        this.channel === null
      ) {
        this.smarabioIsInitialized = false;
        this.$store.dispatch("getInitialData").then(() => {
          this.smarabioIsInitialized = true;
        });
      }
    },
    sessionToken: function (newValue) {
      if (
        newValue !== null &&
        newValue !== undefined &&
        this.channel === null
      ) {
        this.$store.dispatch("getAnonymousSession").then(result => {
          if (result) {
            try {
              // See analytics at https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtm#measure_additions_or_removals_from_a_shopping_cart
              // and https://www.simoahava.com/analytics/google-analytics-4-ecommerce-guide-google-tag-manager/

              // Start checkout process
              window.parent.postMessage(
                JSON.stringify({
                  event: "begin_checkout",
                  ecommerce: this.$store.getters
                    .checkoutObject
                }),
                "*"
              );
            } catch (e) {
              // Do nothing
            }
          }
        });
      }
    },
    $route: function (to, from) {
      this.cartUUID = to.params.order;
      this.organizationUUID = to.params.organization;
      this.channelUUID = to.params.channel;

      if (from.query.sessionToken) {
        this.sessionToken = from.query.sessionToken;
      }

      if (to.query.sessionToken) {
        this.sessionToken = to.query.sessionToken;
      }

      if (
        to.query.language &&
        this.$i18n.availableLocales.includes(
          to.query.language.toLowerCase()
        )
      ) {
        this.$i18n.locale = to.query.language.toLowerCase();
      }

      if (to.name === "channel") {
        this.$router.push({
          path:
            "/" +
            this.organizationUUID +
            "/" +
            this.channelUUID +
            "/cart"
        });
      }

      window.parent.postMessage("routeChangeEvent", "*");
    },
    order() {
      if (this.$route.query.coupon !== null && this.$route.query.coupon !== undefined) {
        this.$store.dispatch("checkIfCouponIsValid", {
          couponCode: this.$route.query.coupon
        }).then(() => {
          this.$store.commit("updateCouponCodeInput", this.$route.query.coupon)
          this.$store.commit("setShowCoupon", false)
        })
      }
    },
    organization(newValue) {
      if (newValue) {
        this.startAnalytics();
      }
    }
  },
  computed: {
    order() {
      return this.$store.state.order
    },
    inIframe() {
      return this.$store.state.inIframe
    },
    rawHTMLFor3DSecure() {
      if (
        this.paymentResponse !== null &&
        this.paymentResponse !== undefined &&
        this.paymentResponse.paymentTransaction !== null &&
        this.paymentResponse.paymentTransaction !== undefined &&
        this.paymentResponse.paymentTransaction.payload !== null &&
        this.paymentResponse.paymentTransaction.payload !== undefined &&
        this.paymentResponse.paymentTransaction.payload[
          "3DSecureHTML"
          ] !== undefined &&
        this.paymentResponse.paymentTransaction.payload[
          "3DSecureHTML"
          ] !== undefined
      ) {
        return decodeURIComponent(
          this.paymentResponse.paymentTransaction.payload[
            "3DSecureHTML"
            ]
        );
      }

      return null;
    },
    paymentResponse() {
      return this.$store.state.paymentResponse;
    },
    version() {
      return version;
    },
    dark() {
      return this.$vuetify.theme.dark;
    },
    items: function () {
      return this.$store.getters.cartItemsForAnalytics;
    },
    analyticsIDs: function () {
      return this.$store.state.analyticsIDs;
    },
    isInitialized: function () {
      if (this.$store.getters.isSmarabio) {
        return this.smarabioIsInitialized;
      }
      return this.$store.getters.isInitialized;
    },
    organization: function () {
      return this.$store.state.organization;
    },
    channel: function () {
      return this.$store.state.channel;
    },
    cartUUID: {
      set(newValue) {
        this.$store.commit("updateCartUUID", newValue);
      },
      get() {
        return this.$store.state.cartUUID;
      }
    },
    organizationUUID: {
      set: function (newValue) {
        this.$store.commit("updateOrganizationUUID", newValue);
      },
      get: function () {
        return this.$store.state.organizationUUID;
      }
    },
    sessionToken: {
      set: function (newValue) {
        this.$store.commit("updateSessionToken", newValue);
      },
      get: function () {
        return this.$store.state.sessionToken;
      }
    },
    channelUUID: {
      set: function (newValue) {
        this.$store.commit("updateChannelUUID", newValue);
      },
      get: function () {
        return this.$store.state.channelUUID;
      }
    },
    gtCode() {
      return this.$store.getters.gtagCode;
    },
    gaCode() {
      return this.$store.getters.gaCode;
    },
    fbPixelCode() {
      return this.$store.getters.fbPixelCode;
    }
  },
  created() {
    this.sessionToken = this.$route.query.sessionToken;

    this.$i18n.locale = "is";
    let language = this.$route.query.language;
    if (
      language !== null &&
      language !== undefined &&
      language.length === 2
    ) {
      this.$i18n.locale = language;
    }
  },
  mounted() {
    try {
        this.$store.commit('updateIframe', window.self !== window.top)
    } catch (e) {
        return true;
    }
    
    if (typeof window !== "undefined" && window !== null) {
      if (
        typeof window.parent !== "undefined" &&
        window.parent !== null
      ) {
        window.parent.postMessage("routeChangeEvent", "*");
        window.parent.postMessage("scrollIntoViewEvent", "*");
      }
    }
    /* GTAG BEGIN CHECKOUT START */
    // this.$gtag.event('begin_checkout', {items: this.items, coupon: ''})
    /* GTAG BEGIN CHECKOUT END */

    if ("parentIFrame" in window) {
      // eslint-disable-next-line no-undef
      parentIFrame.autoResize(false);
      // eslint-disable-next-line no-undef
      parentIFrame.autoResize(true);
    }

    // try {
    //   window.parent.scrollTo(0,0)
    //   window.scrollTo(0,0)
    // } catch (e) {
    //   //
    // }

    let params = new URL(document.location).searchParams;
    let dark = params.get("dark");

    if (dark === "true") {
      this.$vuetify.theme.dark = true;
    }

    this.$store.commit("updateLocationUUID", params.get("l"));
  },
  methods: {
    startAnalytics() {
      if (this.gaCode && this.gtCode) {
        setOptions({
          config: {id: this.gaCode},
          includes: [{id: this.gtCode}]
        });
        bootstrap().then(() => {
        });
      } else if (this.gaCode) {
        setOptions({
          config: {id: this.gaCode}
        });
        bootstrap().then(() => {
        });
      } else if (this.gtCode) {
        setOptions({
          config: {id: this.gtCode}
        });
        bootstrap().then(() => {
        });
      }

      if (this.fbPixelCode) {
        let mainCode =
          "!function(f,b,e,v,n,t,s)\n" +
          "{if(f.fbq)return;n=f.fbq=function(){n.callMethod?\n" +
          "n.callMethod.apply(n,arguments):n.queue.push(arguments)};\n" +
          "if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';\n" +
          "n.queue=[];t=b.createElement(e);t.async=!0;\n" +
          "t.src=v;s=b.getElementsByTagName(e)[0];\n" +
          "s.parentNode.insertBefore(t,s)}(window, document,'script',\n" +
          "'https://connect.facebook.net/en_US/fbevents.js');\n";
        let init = 'fbq("init", "' + this.fbPixelCode + '");\n';
        let track = 'fbq("track", "PageView");\n';

        let script = document.createElement("script");
        script.innerHTML = mainCode + init + track;

        document.head.appendChild(script);
      }
    }
  }
};
</script>

<style>
.full-height {
  height: 100vh;
}
</style>